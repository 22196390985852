<template>
  <div class="projects p-4">
    <h1>Projects</h1>
    <br>
    <div class="row">
      <div class="col-12 center">
        <h2>Coming Soon</h2>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Projects',
  mounted() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },
};
</script>

<style>
.projects {
}

</style>
