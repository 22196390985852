<template>
  <div class="skills p-4">
    <h1>Skills</h1>
    <br>
    <div class="row">
      <div class="col-12 center">
        <div class="skill-container" v-for="skill in skills" :key="skill.id">
          <span class="skill-tag">{{ skill }} </span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Skills',
  data() {
    return {
      skills: [
        'Windows Server',
        'Linux',
        'CentOS',
        'HAProxy',
        'Python',
        'Flask',
        'JavaScript',
        'VueJS',
        'DNS',
        'Cisco Certified',
        'Docker',
        'Docker-Compose',
        'Portainer',
        'Debian/Ubuntu',
        'Routing',
        'PfSense',
        'Cisco ASA',
        'Squid Proxy',
        'OpenVPN',
        'PowerShell',
        'PowerShell',
        'API Design',
        'Bash',
        'Nextcloud',
      ],
    };
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },
};
</script>

<style>
.skills {
}

.center {
  text-align: center;
  justify-content: center;
}

.skill-container {
  float: left;
  padding: 5px;
  padding-top: 10px;
}

.skill-tag {
  background-color: #e7e7e7;
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  margin: 3px;

}
.skill-tag:hover {
  background-color: #2a9d8f;
}

</style>
