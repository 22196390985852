<template>
  <div class="about p-4">

    <h1>Imprint</h1>
    <!--/Legal Disclosure</h1>-->

    <strong>Information in accordance with Section 5 TMG</strong>
    <br>
    <br>
    <p>
      <table>
        <tr>
          <td>Benjamin Schädlich</td>
        </tr>
        <tr>
          <td>Lehrter Straße 2</td>
        </tr>
        <tr>
          <td>10557 Berlin</td>
        </tr>
        <tr>
          <td><br></td>
        </tr>
        <tr>
          <td>Telephone:</td>
          <td>+491573 45 30 124</td>
        </tr>
        <tr>
          <td>E-Mail:</td>
          <td><a href="mailto:mail@benjamin-schaedlich.de">mail@benjamin-schaedlich.de</a></td>
        </tr>
        <tr>
          <td class="pr-3">Internet address:</td>
          <td><a href="https://benjamin-schaedlich.de" target="_blank">https://benjamin-schaedlich.de</a></td>
        </tr>
      </table>
    </p>

    <br>
    <h2>Disclaimer</h2>
    <strong>Accountability for content</strong>
    <p>
      The contents of our pages have been created with the utmost care. However,
      we cannot guarantee the contents'
      accuracy, completeness or topicality. According to statutory provisions,
      we are furthermore responsible for
      our own content on these web pages. In this matter, please note that we are
      not obliged to monitor
      the transmitted or saved information of third parties, or investigate
      circumstances pointing to illegal activity.
      Our obligations to remove or block the use of information under generally
      applicable laws remain unaffected by this as per
      §§ 8 to 10 of the Telemedia Act (TMG).
    </p>

    <strong>Accountability for links</strong>
    <p>
      Responsibility for the content of
      external links (to web pages of third parties) lies solely with the operators
      of the linked pages. No violations were
      evident to us at the time of linking. Should any legal infringement become
      known to us, we will remove the respective
      link immediately.
    </p>

    <strong>Copyright</strong>
    <p>
      Our web pages and their contents are
      subject to German copyright law. Unless
      expressly permitted by law, every form of utilizing, reproducing or processing
      works subject to copyright protection on our web pages requires the prior
      consent of the respective owner of the rights.
      Individual reproductions of a work are only allowed for private use.
      The materials from these pages are copyrighted and any unauthorized use may
      violate copyright laws.
    </p>

    <br>
    <h2>Graphics and Image Sources</h2>
    <p>
      <ul>
        <li v-for="imgCredit in imgCredits" v-bind:key="imgCredit.imgName">
          <a v-bind:href="imgCredit.link"> {{ imgCredit.imgName }}</a> by {{ imgCredit.author }}
        </li>
      </ul>
    </p>

    <br>
    <p style="font-size: 0.7rem;">
      <i>Thanks to: </i><a href="http://www.translate-24h.de" target="_blank">
        translate-24h.de English Translations</a>
    </p>

  </div>
</template>

<script>

export default {
  name: 'Imprint',
  data() {
    return {
      imgCredits: [
        {
          link: 'https://www.flaticon.com/free-icon/gmail_95627?term=mail&page=1&position=3',
          author: 'Freepik',
          imgName: 'Mail Icon',
        },
        {
          link: 'https://www.flaticon.com/free-icon/github_733553?term=github&page=1&position=6',
          author: 'Pixel Perfect',
          imgName: 'GitHub Icon',
        },
        {
          link: 'https://www.flaticon.com/free-icon/gmail_95627?term=mail&page=1&position=3',
          author: 'Freepik',
          imgName: 'Mail Icon',
        },
        {
          link: 'https://www.flaticon.com/free-icon/fiverr_732025?term=fiverr&page=1&position=1',
          author: 'Pixel Perfect',
          imgName: 'Fiverr Icon',
        },
        {
          link: 'https://www.flaticon.com/free-icon/linkedin_145807',
          author: 'Freepik',
          imgName: 'Linkedin Icon',
        },
        {
          link: 'https://www.flaticon.com/free-icon/whatsapp_733585',
          author: 'Pixel Perfect',
          imgName: 'Whatsapp Icon',
        },
        {
          link: 'https://www.flaticon.com/free-icon/facebook_179319',
          author: 'Pixel Buddha',
          imgName: 'Facebook Icon',
        },
        {
          link: 'https://www.flaticon.com/free-icon/telegram_2111646',
          author: 'Pixel Perfect',
          imgName: 'Telegram Icon',
        },

        {
          link: 'https://www.pexels.com/photo/computer-keyboard-34153/',
          author: 'Negative Space',
          imgName: 'Background Image',
        },
        {
          link: 'https://www.pexels.com/photo/waiting-lines-moving-steel-34621/',
          author: 'Negative Space',
          imgName: 'Background Image',
        },
        {
          link: 'https://www.pexels.com/photo/train-tunnel-train-station-platform-134579/',
          author: 'Negative Space',
          imgName: 'Background Image',
        },
        {
          link: 'https://www.pexels.com/photo/commuter-escalator-indoors-low-angle-shot-134578/',
          author: 'Negative Space',
          imgName: 'Background Image',
        },
        {
          link: 'https://www.pexels.com/photo/person-using-laptop-computer-in-gray-scale-160109/',
          author: 'Negative Space',
          imgName: 'Background Image',
        },

      ],
    };
  },
  mounted() {
    console.log('here');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },
};
</script>

<style>
