<template>
  <div class="cv p-4">
    <h1>Curriculum Vitae</h1>
    <br>
    <div class="row">
      <div class="col-12 center">
        <label for="">Password</label>
        <input class="form-control" type="password" v-model="password" name="" value="">
      </div>
      <div class="col-12 center p-4">
        <button class="btn btn-primary" @click="checkPassword()"
          type="button" name="button">Download</button>
      </div>

    </div>

  </div>
</template>

<script>

export default {
  name: 'CV',
  mounted() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },
  data() {
    return {
      password: null,
    };
  },
  methods: {
    checkPassword: function checkPassword() {
      console.log('');
    },
  },
};
</script>

<style>
.cv {
}

</style>
